/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useMemo } from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'

import clientConfig from '../../client-config'

export const Figure = ({
  node,
  maxWidth = 800,
  noCaption = false,
  heightBased = false
}) => {
  if (!node || !node.asset || !node.asset._id) {
    return null
  }

  const rotationX = useMemo(() => Math.random() * 3 - 1.5)
  const rotationY = useMemo(() => Math.random() * 3 - 1.5)
  const rotation = useMemo(() => Math.random() * 1 - 0.5)

  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth },
    clientConfig.sanity
  )

  return (
    <figure
      sx={{
        m: 0,
        p: 0,
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        perspective: '600px'
      }}
    >
      <Img
        fluid={fluidProps}
        alt={node.alt}
        backgroundColor={heightBased ? 'transparent' : '#eee'}
        sx={{
          width: '100%',
          height: '100%',
          transition: 'transform 0.3s ease',
          transform: `rotateX(${rotationX}deg) rotateY(${rotationY}deg) rotateZ(${rotation}deg)`,
          '& > div': {
            paddingBottom: heightBased && '0 !important'
          }
        }}
        imgStyle={{ objectFit: 'contain' }}
      />
      {!noCaption && node.caption && node.caption !== '' && (
        <figcaption sx={{ fontFamily: 'small', color: 'primary', fontSize: 0 }}>
          {node.caption}
        </figcaption>
      )}
    </figure>
  )
}
