/** @jsx jsx */
import ReactPlayer from 'react-player'
import { useState } from 'react'
import { Box, Text, jsx } from 'theme-ui'

function getPosterSrc(playbackId, options = {}) {
  const width = options.width || 640
  const height = options.height || ''
  const time = options.time || 1
  const fitMode =
    typeof options.fitMode === 'undefined' ? 'smartcrop' : options.fitMode
  let url = `https://image.mux.com/${playbackId}/thumbnail.png?width=${width}&fit_mode=${fitMode}&time=${time}`
  if (options.height) {
    url += `&height=${height}`
  }
  return url
}

export const Video = ({ node, poster, autoPlay, muted, children }) => {
  const [isLoading] = useState(
    () => node.video && node.video.asset.status !== 'ready'
  )

  const [source] = useState(
    () =>
      node.video &&
      node.video.asset.playbackId &&
      `https://stream.mux.com/${node.video.asset._id}.m3u8`
  )
  const [posterURL] = useState(() =>
    poster && typeof poster === 'string'
      ? poster
      : getPosterSrc(node.video.asset._id, {
          time: node.video.asset.thumbTime || 1,
          fitMode: 'preserve'
        })
  )

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          paddingTop: '56.25%',
          background: '#eee'
        }}
      >
        {isLoading ? (
          <Text
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              textAlign: 'center',
              fontFamily: 'small',
              color: '#aaa',
              transform: 'translate(-50%, -50%)'
            }}
          >
            Loading…
          </Text>
        ) : (
          <ReactPlayer
            url={source}
            controls
            width="100%"
            height="100%"
            muted={autoPlay || muted}
            autoPlay={autoPlay}
            poster={posterURL}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />
        )}
      </Box>
      {children}
    </Box>
  )
}
