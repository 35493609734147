/** @jsx jsx */
import getWeek from 'date-fns/getWeek'
import { Grid, Heading, Box, Text, jsx } from 'theme-ui'

import { PortableText } from './PortableText'
import { Figure } from './Figure'
import { Video } from './Video'
import { Dash } from './Dash'
import { Highlights } from './Highlights'
import { Note } from './Note'

export const Post = ({
  id,
  slug: { current },
  _rawBody,
  highlights,
  allHighlights,
  title,
  location = 'Unknown location',
  coffee,
  images,
  note,
  publishedAt,
  handleHighlight
}) => {
  return (
    <Box>
      <a sx={{ visibility: 'hidden' }} id={current} name={current}>
        {current}
      </a>
      <Grid
        as="article"
        sx={{
          maxWidth: '1280px',
          ml: 'auto',
          mr: 'auto',
          p: [4, 5],
          columnGap: [4, 4, 5],
          rowGap: 0,
          alignItems: 'flex-start',
          gridTemplateColumns: ['100%', '100%', '1fr 1fr']
        }}
      >
        <Grid
          sx={{
            columnGap: 0,
            rowGap: 3,
            position: ['static', 'static', 'sticky'],
            top: 4
          }}
        >
          {images.map(
            image =>
              image && (
                <div key={image._key}>
                  {image.__typename === 'SanityMainImage' && image.asset ? (
                    <Figure node={image} />
                  ) : (
                    image.video && <Video node={image} poster />
                  )}
                </div>
              )
          )}
        </Grid>
        <Grid
          sx={{
            gap: 1,
            position: 'sticky',
            top: 4,
            mt: [4, 4, 0],
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            gridTemplateColumns: '1fr',
            gridTemplateRows:
              'max-content max-content max-content 1fr max-content'
          }}
        >
          {publishedAt && (
            <time sx={{ fontFamily: 'small', color: 'primary' }}>
              Week {getWeek(new Date(publishedAt))}
            </time>
          )}
          <Heading>{title}</Heading>
          <Grid gap={0} sx={{ mt: 4, mb: 5, color: 'darkGray' }}>
            {location && <Text variant="small">{location}</Text>}
            {coffee && (
              <Text variant="small">
                <Dash /> {coffee}
              </Text>
            )}
          </Grid>

          {_rawBody && (
            <Grid sx={{ fontSize: 3, mb: 5 }}>
              <PortableText blocks={_rawBody} />
            </Grid>
          )}

          {highlights && (
            <Highlights
              highlights={highlights}
              allHighlights={allHighlights}
              handleClick={handleHighlight}
            />
          )}
        </Grid>
      </Grid>
      {note && note.title && note.title !== '' && (
        <Note note={note} id={`note${id}`} postTitle={title} />
      )}
    </Box>
  )
}
