/** @jsx jsx */
import { Grid, Box, Text, jsx } from 'theme-ui'
import { useMemo } from 'react'

import { Dash } from './Dash'
import { useStore } from './store'

const Highlight = ({ mapId, isFirst, caption, handleClick }) => (
  <Grid
    as="li"
    sx={{
      m: 0,
      p: 0,
      position: 'relative',
      color: 'darkGray',
      gridTemplateColumns: 'max-content 1fr',
      '&:hover': {
        cursor: 'pointer',
        color: 'primary'
      }
    }}
    onClick={handleClick}
  >
    {isFirst && (
      <Dash sx={{ position: 'absolute', top: '1.2em', left: '-0.7em' }} />
    )}
    <Text sx={{ fontFamily: 'pirelli' }}>{mapId}</Text>
    <Text sx={{ fontFamily: 'pirelli' }}>{caption}</Text>
  </Grid>
)

export const Highlights = ({ highlights, allHighlights }) => {
  const setSelected = useStore(state => state.actions.setSelected)

  const ids = useMemo(() => {
    return highlights.map(a => a.id)
  }, [highlights])
  const associatedHighlights = useMemo(() =>
    allHighlights.filter(h => ids.includes(h.id))
  )

  return highlights && highlights.length > 0 ? (
    <Box sx={{ mt: 5 }}>
      <Text as="div" variant="small" color="primary" sx={{ mb: 3 }}>
        Highlights
      </Text>

      <Grid
        gap={0}
        as="ul"
        sx={{
          m: 0,
          p: 0,
          alignSelf: 'flex-end',
          listStyle: 'none',
          rowGap: 1
        }}
      >
        {highlights.map((highlight, i) => {
          const match = allHighlights.findIndex(h => h.id === highlight.id)
          return (
            <Highlight
              key={`highlight-${i}`}
              isFirst={i === 0 && highlights.length > 1}
              caption={highlight.caption}
              mapId={match !== -1 && match + 1}
              handleClick={() =>
                setSelected(allHighlights[match], associatedHighlights)
              }
            />
          )
        })}
      </Grid>
    </Box>
  ) : null
}
