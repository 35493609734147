import create from 'zustand'
import { LngLatBounds } from 'mapbox-gl'

export const useStore = create((set, get) => ({
  selected: undefined,
  bounds: undefined,
  boundsCenter: undefined,
  mapOpen: false,
  indexOpen: false,
  actions: {
    toggleIndex(open) {
      set({ indexOpen: open })
    },
    openIndex() {
      set({ indexOpen: true })
    },
    closeIndex() {
      set({ indexOpen: false })
    },
    toggleMap(open) {
      set({ mapOpen: open })
    },
    openMap() {
      set({ mapOpen: true })
    },
    closeMap() {
      set({ mapOpen: false })
    },
    setSelected(selected, boundPoints) {
      if (boundPoints) get().actions.setBounds(boundPoints)
      set({ selected, mapOpen: selected ? true : get().mapOpen })
    },
    setBounds(points) {
      if (points.length < 2) return
      const firstTwo = points.slice(0, 2)
      const b = new LngLatBounds(
        points.map(({ location: { lng, lat } }) => ({ lng, lat }))
      )
      // const b = LngLatBounds.convert(
      //   firstTwo.map(({ location: { lng, lat } }) => ({ lng, lat }))
      // )

      // points.slice(2).forEach(({ location: { lng, lat } }) => {
      //   b.extend({ lng, lat })
      // })
      const center = b.getCenter()
      set({ bounds: b.toArray(), boundsCenter: [center.lng, center.lat] })
    }
  }
}))
