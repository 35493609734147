/** @jsx jsx */
import { jsx, Themed } from 'theme-ui'

const note = props => {
  return <span sx={{ color: 'primary' }}>{props.children}</span>
}

const link = ({ children, mark }) => (
  <Themed.a href={mark.href}>{children}</Themed.a>
)

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return <Themed.h1>{props.children}</Themed.h1>
        case 'h2':
          return <Themed.h2>{props.children}</Themed.h2>
        case 'h3':
          return <Themed.h3>{props.children}</Themed.h3>
        case 'h4':
          return <Themed.h4>{props.children}</Themed.h4>
        case 'h5':
          return <Themed.h5>{props.children}</Themed.h5>
        case 'h6':
          return <Themed.h6>{props.children}</Themed.h6>
        case 'blockquote':
          return <Themed.blockquote>{props.children}</Themed.blockquote>
        default:
          return <Themed.p>{props.children}</Themed.p>
      }
    }
  },
  marks: {
    note,
    link
  }
}

export default serializers
