/** @jsx jsx */
import { jsx } from 'theme-ui'
import BasePortableText from '@sanity/block-content-to-react'

import clientConfig from '../../client-config'
import serializers from '../lib/serializers'

export const PortableText = ({ blocks }) => (
  <div
    sx={{
      maxWidth: '32em',
      p: {
        m: 0
      },
      'p + p': {
        textIndent: '1.5em'
      }
    }}
  >
    <BasePortableText
      blocks={blocks}
      serializers={serializers}
      {...clientConfig.sanity}
    />
  </div>
)
