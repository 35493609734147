/** @jsx jsx */
import * as React from 'react'
import { Grid, Text, Box, jsx } from 'theme-ui'
import Flickity from 'react-flickity-component'
import 'flickity/css/flickity.css'

import { PortableText } from './PortableText'
import { Dash } from './Dash'
import { Figure } from './Figure'
import { Video } from './Video'

const flickityOptions = {
  freeScroll: false,
  pageDots: false,
  setGallerySize: false
}

export const Note = ({ note, id, postTitle }) => {
  return (
    <React.Fragment>
      <a id={id} name={id} sx={{ visibility: 'hidden' }}>
        Note for {postTitle}
      </a>

      <Grid
        sx={{
          position: 'relative',
          my: 5,
          py: [4, 5, 6],
          gridTemplateColumns:
            note.images.length > 0
              ? ['1fr', '1fr', '1fr 1fr']
              : ['100%', '100%', '50%'],
          gridTemplateRows: 'auto',
          justifyContent: note.images.length === 0 && 'center',
          minHeight: '50vmin',
          columnGap: [4, 4, 5]
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1501 1057"
          sx={{
            position: 'absolute',
            zIndex: -5,
            width: '100%',
            height: '100%',
            top: '55%',
            transform: 'translateY(-50%)'
          }}
        >
          <path
            sx={{ fill: 'primary' }}
            d="M233.79-241.107h1037.972c1.964 284.75-103.023 547.33-314.96 787.741-211.936 240.411-452.94 343.322-723.013 308.733V-241.107z"
            transform="rotate(-127 752.79 310.393)"
          />
        </svg>
        <Grid
          sx={{
            p: [0, 0, 2],
            ml: 5,
            mr: [5, 5, 0],
            alignContent: 'flex-start'
          }}
        >
          <Text variant="small">
            <span sx={{ color: 'lightGray' }}>Note</span>
            <br />
            {note.title}
          </Text>
          <Dash color="white" />
          <Box
            sx={{
              fontSize: [2, '18px'],
              a: {
                color: 'black',
                '&:hover': {
                  textDecoration: 'none'
                }
              }
            }}
          >
            <PortableText blocks={note._rawBody} />
          </Box>
        </Grid>
        {note.images.length > 0 && (
          <Grid sx={{ mr: 5, minHeight: '33vh', p: 3, ml: [5, 5, 0] }}>
            <Flickity
              options={flickityOptions}
              static
              sx={{
                '.carousel': { height: '100%' },
                '.carousel-cell': { width: '100%', height: '100%' },
                '.flickity-prev-next-button': {
                  width: 30,
                  height: 30,
                  borderRadius: 0
                },
                '.flickity-button-icon': {
                  fill: 'primary'
                },
                '.flickity-prev-next-button.previous': {
                  left: 10
                },
                '.flickity-prev-next-button.next': {
                  right: 10
                }
              }}
            >
              {note.images.map(image => (
                <Box
                  key={image._key}
                  sx={{
                    p: 2,
                    display: 'grid',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  {image.__typename === 'SanityMainImage' && image.asset ? (
                    <Figure node={image} heightBased />
                  ) : (
                    image.video && <Video node={image} poster />
                  )}
                </Box>
              ))}
            </Flickity>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}
